import {useEffect, useState} from 'react'
import clsx from 'clsx'
import {useRouter} from 'next/router'
import {useTranslation} from 'next-i18next'
import IconButton from '@material-ui/core/IconButton'
import AppButton from 'src/components/elements/buttons/AppButton'
import AppIcon from 'src/components/elements/icons/AppIcon'
import AppTypographyLink from 'src/components/elements/AppTypographyLink'
import Loading from 'src/components/elements/Loading'
import Logo from 'src/components/modules/globals/header/components/Logo'
import {
  PROPERTY_PAGE_CHALETS_A_LOUER,
  PROPERTY_PAGE_COTTAGE_RENTAL,
  SEARCH_RESULT_PAGE_EN,
  SEARCH_RESULT_PAGE,
  BASE_URL,
  ADD_NEW_PROPERTY_PAGE,
} from 'src/constants/route'
import {LOCALE_EN} from 'src/constants/locale'
import {useAddListingContext} from 'src/context/AddListingProvider'
import {useAppContext} from 'src/context/AppProvider'
import {useIsHost, useIsLoggedIn} from 'src/hooks/user'
import {useCurrentLocale} from 'src/hooks/locale'
import {useHasNewNotification} from 'src/hooks/user'
import {useStyles} from './styles'

import ProfilePopUp from 'src/components/modules/globals/header/components/ProfilePopUp'
import SearchSidebar from 'src/components/modules/globals/header/components/SearchSidebar'
import SearchBoxInTop from 'src/components/modules/home/homeHero/SearchBoxInTop'
import LanguageChangeButton from 'src/components/modules/globals/header/components/LanguageChangeButton'
import ContactUsMenuPopUp from 'src/components/modules/globals/header/components/ContactUsMenuPopUp'
import ArrowUpIcon from 'src/components/elements/icons/ArrowUp'
import ArrowDownIcon from 'src/components/elements/icons/ArrowDown'
import Badge from 'src/components/elements/Badge'

export default function TopBar(props: {
  open: boolean
  onOpenDrawer: () => void
  onOpenMenu: (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => void
}) {
  const {open, onOpenDrawer, onOpenMenu} = props

  const [isSearchSidebarOpen, setIsSearchSidebarOpen] = useState(false)
  const [isTop, setIsTop] = useState(true)

  const handleCloseSearchSidebar = () => setIsSearchSidebarOpen(false)
  const handleOpenSearchSidebar = () => setIsSearchSidebarOpen(true)

  const isLoggedIn = useIsLoggedIn()
  const router = useRouter()
  const {loading: loadingPropertyInfo, form} = useAddListingContext()

  const {t} = useTranslation(['header', 'inbox', 'common'])
  const hasNewNotification = useHasNewNotification()

  const currentLocale = useCurrentLocale()
  const searchPage =
    currentLocale === LOCALE_EN ? SEARCH_RESULT_PAGE_EN : SEARCH_RESULT_PAGE
  const isSearchPage = router.pathname === searchPage
  const isHomepage = router.pathname === '/'
  const classes = useStyles({
    isTop,
    isHomepage,
  })
  const {setModal} = useAppContext()
  const isHost = useIsHost()

  const baseURL =
    currentLocale === LOCALE_EN ? `${BASE_URL}/${LOCALE_EN}` : BASE_URL

  const baseURLForCottageRental =
    currentLocale === LOCALE_EN
      ? `${baseURL}/${PROPERTY_PAGE_COTTAGE_RENTAL}`
      : `${baseURL}/${PROPERTY_PAGE_CHALETS_A_LOUER}`

  const onScroll = () => {
    setIsTop(window.scrollY <= 470)
  }

  //on mounted set top value
  useEffect(() => {
    setIsTop(window.scrollY <= 470)
  }, [])

  useEffect(() => {
    if (isSearchPage) {
      setIsTop(false)
    }
    if (window && !isSearchPage) {
      document.addEventListener('scroll', onScroll, {passive: true})
    }
    return () => {
      document.removeEventListener('scroll', onScroll)
    }
  }, [isSearchPage])

  const handleNewProperty = (e: any) => {
    e.preventDefault()
    if (isLoggedIn) {
      form.reset()
      router.push(ADD_NEW_PROPERTY_PAGE)
      return
    }

    setModal('question')
  }

  return (
    <header
      className={clsx(
        classes.appBar,
        isTop ? '-top' : '',
        isHomepage ? '-homepage' : '',
      )}
    >
      <div className="relative">
        <div className={classes.toolbar}>
          <div className="flex flex-row justify-start items-center">
            <Logo isTop={isTop} />
            <div className="hidden md:block">
              <Search hidden={isTop} />
            </div>
          </div>
          <ul className={clsx(classes.flexWrapper, classes.headerActionBtn)}>
            <li>
              <div
                className="flex flex-row justify-center items-center"
                onMouseEnter={onOpenMenu}
              >
                <div className="flex flex-row justify-start items-center">
                  <AppTypographyLink
                    href={baseURLForCottageRental}
                    className={classes.linkBtn}
                  >
                    {t('cottage_rental', {ns: 'common'})}
                  </AppTypographyLink>
                  {open ? (
                    <ArrowUpIcon />
                  ) : (
                    <span onClick={onOpenMenu} className="block cursor-pointer">
                      <ArrowDownIcon />
                    </span>
                  )}
                </div>
              </div>
            </li>
            <li>
              <AppTypographyLink
                href=""
                shouldRefresh
                onClick={handleNewProperty}
                className={classes.linkBtn}
              >
                {t(isHost ? 'add_a_property' : 'become_a_host')}
              </AppTypographyLink>
            </li>
            <li className={isLoggedIn ? 'hidden' : ''}>
              <LoginButton isTop={isTop} />
            </li>
            <li>
              <LanguageChangeButton isTop={isTop} />
            </li>
            <li className={isTop ? '' : 'hidden xl:block'}>
              <ContactUsMenuPopUp isTop={isTop} />
            </li>
            <li>
              <ProfilePopUp isTop={isTop} />
            </li>
          </ul>
          <div className="block lg:hidden">
            <div>
              <IconButton
                onClick={handleOpenSearchSidebar}
                className={classes.menuBtn}
              >
                <AppIcon name={'search-icon-black'} />
              </IconButton>
              <IconButton
                onClick={onOpenDrawer}
                className={clsx(classes.menuBtn, '-mr-3')}
              >
                <Badge visible={hasNewNotification}>
                  <AppIcon name={'menu-line-dark'} />
                </Badge>
              </IconButton>
            </div>
          </div>
        </div>
        <Loading loading={loadingPropertyInfo} />
      </div>
      <SearchSidebar
        open={isSearchSidebarOpen}
        onClose={handleCloseSearchSidebar}
      />
    </header>
  )
}

function Search(props: {hidden: boolean}) {
  if (props.hidden) {
    return null
  }
  //todo use  <div className={hidden ? 'hidden' : 'block'}>

  return <SearchBoxInTop inTopBar />
}

function LoginButton(props: {isTop: boolean}) {
  const {isTop} = props

  const router = useRouter()
  const isHomepage = router.pathname === '/'
  const classes = useStyles({isTop, isHomepage})
  const {t} = useTranslation('header')
  const {setModal} = useAppContext()
  const isLoggedIn = useIsLoggedIn()
  if (isLoggedIn) {
    return null
  }

  return (
    <AppButton
      className={classes.transparentBtn}
      onClick={() => setModal('question')}
    >
      {t('login')}
    </AppButton>
  )
}
