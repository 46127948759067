import {makeStyles} from '@material-ui/core/styles'
import AppRoundedButton from 'src/components/elements/buttons/AppRoundedButton'
import ArrowDownIcon from 'src/components/elements/icons/ArrowDown'
import {useCurrentLocale} from 'src/hooks/locale'
import {useAppContext} from 'src/context/AppProvider'
import {LOCALE_FR} from 'src/constants/locale'

interface Props {
  isTop?: boolean
}

//todo convert to styles.module.css or tailwind utility classes
const useStyles = makeStyles(({palette, spacing}) => ({
  transparentBtn: ({isTop}: Props) => ({
    background: isTop ? 'transparent' : undefined,
    borderColor: palette.text.primary,
    color: palette.grey[600], //isTop ? palette.text.primary :
    padding: spacing(1),
    height: '40px',
    '& span': {
      lineHeight: 'normal',
    },
  }),
}))

export default function LanguageChangeButton(props: {
  isListing?: boolean
  isTop?: boolean
}) {
  const {isListing, isTop} = props

  const classes = useStyles({isTop: isListing ? false : isTop})
  const currentLocale = useCurrentLocale()

  const {setModal} = useAppContext()

  const openModal = () => setModal('languages')

  return (
    <>
      <AppRoundedButton
        className={classes.transparentBtn}
        endIcon={
          <ArrowDownIcon color={isListing || !isTop ? 'action' : 'inherit'} />
        }
        variant={isListing ? 'outlined' : undefined}
        onClick={openModal}
      >
        {currentLocale === LOCALE_FR ? 'Fr' : 'En'}
      </AppRoundedButton>
    </>
  )
}
