import {useEffect, useRef, useState} from 'react'
import {useTranslation} from 'next-i18next'
import {makeStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import {BaseStepProps} from 'src/components/modules/home/homeHero/mobileSearch/MobileSearch'
import Container from 'src/components/helpers/Container'
import {useSelector, useDispatch} from 'react-redux'
import {
  getSelectedGuests,
  getSelectedLocation,
} from 'src/store/selectors/propertySearchSelectors'
import {
  setSelectedLocation,
  setMapBounds,
  setMapCenterBounds,
  setSortByFilter,
} from 'src/store/actions/propertySearchActions'
import {getSearchColumns} from 'src/constants/property'
import {useBreakpoint} from 'src/hooks/screenSize'
import {AutocompleteLocationType} from 'src/types/location'
import {useDebounceFunc} from 'src/hooks/other'
import axios, {CancelTokenSource} from 'axios'
import {publicGetLocationAutocompleteSearch} from 'src/services/api/location'
import {LocationSearch} from 'src/types/search'

//todo convert to styles.module.css or tailwind utility classes
const useStyles = makeStyles(({spacing, breakpoints}) => ({
  input: {
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: spacing(3),
    },
    '& .MuiInputBase-input': {
      fontSize: 14,
      padding: spacing(15 / 8, 14 / 8),
    },
  },
  loader: {
    padding: spacing(0, 1.5),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  resultsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: spacing(10 / 8),
    gap: '8px',
  },
  supPopularTitle: {
    display: 'block',
    paddingBottom: spacing(1.5),
    margin: '0 auto',
    textAlign: 'left',
    fontSize: '14px',
    lineHeight: '22px',
  },
  popularTitle: {
    display: 'block',
    fontSize: 18,
    fontWeight: 600,
    [breakpoints.down('md')]: {
      paddingTop: spacing(3),
    },
  },
  resultItem: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    gap: 16,
    fontSize: 14,
    lineHeight: '28px',
    fontWeight: 500,
    color: '#525252',
    padding: spacing(0.75, 0),
  },
}))

export default function LocationWithHoc({changeStep}: BaseStepProps) {
  const {t} = useTranslation('common')
  const classes = useStyles()
  const dispatch = useDispatch()

  const [selectedLocationText, setSelectedLocationText] = useState<string>('')
  const [status, setStatus] = useState<'standby' | 'loading' | 'loaded'>(
    'standby',
  )
  const selectedLocation = useSelector(getSelectedLocation)
  const selectedGuests = useSelector(getSelectedGuests)
  const [suggestions, setSuggestions] = useState<AutocompleteLocationType[]>([])
  const fetchCancelSource = useRef<CancelTokenSource | null>(null)
  const [value, setValue] = useState<string>(
    selectedLocation && selectedLocation.address
      ? selectedLocation.address
      : '',
  )

  useEffect(() => {
    if (value) {
      fetchSuggestions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchSuggestions = useDebounceFunc(
    () => {
      if (status !== 'loading') {
        setStatus('loading')
      }
      if (suggestions.length !== 0) {
        setSuggestions([])
      }

      if (fetchCancelSource.current) {
        fetchCancelSource.current.cancel()
      }

      //set new token
      fetchCancelSource.current = axios.CancelToken.source()

      if (!value) {
        setStatus('loaded')
        return
      }

      publicGetLocationAutocompleteSearch(
        value,
        fetchCancelSource.current?.token,
      )
        .then((result) => {
          setSuggestions(result.results)
          setStatus('loaded')
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            return
          }
          //will be err cancel sometimes
          // eslint-disable-next-line no-console
          console.log(err)
        })
    },
    100,
    [value],
  )

  const handleSearchQueryChange = (e: any) => {
    let value = e.target.value
    setValue(value)
    setSelectedLocationText(e.target.value)

    if (value) {
      setStatus('loading') //no debounce
      setSuggestions([]) //no debounce
      fetchSuggestions()
      return
    }

    //if empty
    setSuggestions([])
    dispatch(
      setSelectedLocation({
        address: null,
        automatic: false,
        autocompleteLocation: null,
        addressComps: {
          country: null,
          state: null,
          city: null,
          mrc: null,
          county: null,
        },
        coordinates: null,
      }),
    )
    const adultCount = selectedGuests?.adults
      ? Number(selectedGuests.adults)
      : 0
    const childrenCount = selectedGuests?.children
      ? Number(selectedGuests.children)
      : 0

    const guestCount = adultCount + childrenCount
    if (guestCount > 0) {
      dispatch(setSortByFilter('relevance'))
    }
    dispatch(setMapBounds(null))
    dispatch(setMapCenterBounds(null))
  }

  const handleSelectLocation = async (address: string) => {
    setValue(address)

    const fromSuggestionLocation: AutocompleteLocationType | null =
      suggestions.find((item) => item.title === address) || null

    const location: LocationSearch = {
      address,
      automatic: false,
      autocompleteLocation: fromSuggestionLocation,
      addressComps: {
        country: null,
        state: null,
        city: null,
        mrc: null,
        county: null,
      },
      coordinates: {
        lat: fromSuggestionLocation ? fromSuggestionLocation.lat : null,
        lng: fromSuggestionLocation ? fromSuggestionLocation.lng : null,
      },
    }

    changeStep('date')
    setSelectedLocationText(address)
    dispatch(setSelectedLocation(location))
    dispatch(setSortByFilter('distance_low_to_high'))
  }

  const handleSelectPreDefinedLocation = async (
    address: string,
    lat: number,
    lng: number,
  ) => {
    setValue(address)
    //todo remove try catch
    try {
      const location: LocationSearch = {
        address,
        automatic: false,
        autocompleteLocation: null,
        addressComps: {
          country: null,
          state: null,
          city: null,
          mrc: null,
          county: null,
        },
        coordinates: {
          lat,
          lng,
        },
      }

      changeStep('date')
      setSelectedLocationText(address)
      dispatch(setSelectedLocation(location))
      dispatch(setSortByFilter('distance_low_to_high'))
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('😱 Error: ', error)
    }
  }

  return (
    <Container>
      <>
        <div className={classes.input}>
          <TextField
            placeholder={t('location_description')}
            variant="outlined"
            size="small"
            value={value}
            onChange={handleSearchQueryChange}
            id="places_input"
            name="location_description_steps"
          />
        </div>
        <div className={classes.resultsContainer} id="places_selection">
          <Locations
            status={status}
            searchValue={selectedLocationText}
            locations={suggestions}
            handleSelectLocation={handleSelectLocation}
            handleSelectPreDefinedLocation={handleSelectPreDefinedLocation}
          />
        </div>
      </>
    </Container>
  )
}

function Locations(props: {
  status: 'loading' | 'loaded' | 'standby'
  searchValue?: string
  locations: AutocompleteLocationType[]
  handleSelectLocation: (description: string) => void
  handleSelectPreDefinedLocation: (
    address: string,
    lat: number,
    lng: number,
  ) => void
}) {
  const {
    locations,
    handleSelectLocation,
    handleSelectPreDefinedLocation,
    status,
    searchValue,
  } = props

  const {t} = useTranslation('common')
  const classes = useStyles()
  const breakpoint = useBreakpoint()

  const searchColumnsData = getSearchColumns(t)

  return (
    <>
      <div>
        {status === 'loading' && (
          <div className={classes.loader}>
            <CircularProgress size={12} />
          </div>
        )}
        {status !== 'loading' && locations.length === 0 && searchValue && (
          <span className={classes.supPopularTitle}>
            {t('no_search_results', {search: searchValue})}
          </span>
        )}
        {locations.length > 0 && (
          <>
            {locations.map((item: AutocompleteLocationType, idx: number) => (
              <Location
                key={idx}
                description={item.title}
                handleSelectLocation={() => handleSelectLocation(item.title)}
              />
            ))}
          </>
        )}
      </div>

      <Grid container>
        {searchColumnsData.map((column, index) => (
          <Grid key={index} item xs={12} sm={12} md={4} lg={4} xl={4}>
            <span className={classes.popularTitle}>{column.title}</span>
            <div className="pt-2">
              {column.locations.map((location) => (
                <Location
                  handleSelectLocation={() => {
                    if (!breakpoint) return
                    const locationForBreakpoint = location.options[breakpoint]
                    handleSelectPreDefinedLocation(
                      location.label,
                      locationForBreakpoint[1], //lat
                      locationForBreakpoint[2], //lng
                    )
                  }}
                  key={location.label}
                  description={location.label}
                />
              ))}
            </div>
          </Grid>
        ))}
      </Grid>
    </>
  )
}

function Location(props: {
  description: string
  handleSelectLocation: () => void
}) {
  const {description, handleSelectLocation} = props

  const classes = useStyles()

  return (
    <div className={classes.resultItem} onClick={handleSelectLocation}>
      {description}
    </div>
  )
}
