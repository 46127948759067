import {useMemo, useRef, useState} from 'react'
import {useRouter} from 'next/router'
import {useStyles} from './styles'
import {useTranslation} from 'next-i18next'
import {useSelector, useDispatch} from 'react-redux'
import AppButton from 'src/components/elements/buttons/AppButton'
import {GuestSelectProps} from 'src/types/guestSelect'
import {SEARCH_RESULT_PAGE, SEARCH_RESULT_PAGE_EN} from 'src/constants/route'
import {useCurrentLocale} from 'src/hooks/locale'

import Field from 'src/components/modules/home/homeHero/mobileSearch/Field'
import SearchDrawer from 'src/components/modules/home/homeHero/mobileSearch/SearchDrawer'
import GuestField from 'src/components/modules/home/homeHero/mobileSearch/GuestField'

import LocationStep from 'src/components/modules/home/homeHero/mobileSearch/steps/Location'
import DateStep from 'src/components/modules/home/homeHero/mobileSearch/steps/Date'
import GuestsStep from 'src/components/modules/home/homeHero/mobileSearch/steps/Guests'
import FiltersStep from 'src/components/modules/home/homeHero/mobileSearch/steps/Filters'

import {getSelectedLocation} from 'src/store/selectors/propertySearchSelectors'
import {getSelectedDateRange} from 'src/store/selectors/propertySearchSelectors'
import {getSelectedGuests} from 'src/store/selectors/propertySearchSelectors'
import {
  fetchSearchData,
  setClickedPropertyUid,
  setScrollY,
  setSearchPageNo,
} from 'src/store/actions/propertySearchActions'
import Container from 'src/components/helpers/Container'
import {LOCALE_EN} from 'src/constants/locale'
import {getSearchUrl} from 'src/utils/search'
import {useSmallerSize} from 'src/hooks/screenSize'

import {getSimpleFormattedDate} from 'src/hooks/date'

type Steps = 'location' | 'date' | 'guests' | 'filters'

const StepDetails: {
  [key in Steps]: {
    label: string
    fullHeight?: boolean
    customHeight?: string
  }
} = {
  location: {
    label: 'location',
    fullHeight: true,
  },
  guests: {
    label: 'guests',
  },
  date: {
    label: 'dates',
    customHeight: '95%',
  },
  filters: {
    label: 'filters',
    customHeight: '85%',
  },
}

const componentMapping: {
  [key in Steps]: React.ElementType
} = {
  location: LocationStep,
  date: DateStep,
  guests: GuestsStep,
  filters: FiltersStep,
}

export interface BaseStepProps {
  changeStep: (step: Steps | null) => void
  scrollToTop?: () => void
}

export default function MobileSearch(props: {
  elivated?: boolean
  onSearched?: () => void
}) {
  const {elivated, onSearched} = props

  const router = useRouter()
  const {t} = useTranslation('common')
  const currentLocale = useCurrentLocale()
  const dispatch = useDispatch()
  const searchPage =
    currentLocale === LOCALE_EN ? SEARCH_RESULT_PAGE_EN : SEARCH_RESULT_PAGE

  const [currentStep, setCurrentStep] = useState<Steps | null>(null)
  const selectedLocation = useSelector(getSelectedLocation)
  const selectedDateRange = useSelector(getSelectedDateRange)
  const guests = useSelector(getSelectedGuests)

  const showGuests = useMemo(() => {
    if (!guests) return false
    for (let key in guests) {
      if (guests[key as keyof GuestSelectProps] >= 1) {
        return true
      }
    }
    return false
  }, [guests])

  const handleSearch = () => {
    dispatch(setSearchPageNo(1))

    dispatch(setScrollY(0))
    dispatch(setClickedPropertyUid(null))

    if (router.route !== searchPage) {
      const searchUrl = getSearchUrl(currentLocale)
      router.push(searchUrl)
    }
    dispatch(fetchSearchData(1))

    if (onSearched) {
      onSearched()
    }
  }

  const changeStep = (step: Steps | null) => {
    setCurrentStep(null)

    //Disabling it for now we might add automatic transition later.

    // if (step === null) return

    // setTimeout(() => {
    //   setCurrentStep(step)
    // }, 300)
  }

  const handleDrawerClose = () => setCurrentStep(null)

  const startDate = getSimpleFormattedDate(
    selectedDateRange.startDate,
    currentLocale,
  )

  const endDate = getSimpleFormattedDate(
    selectedDateRange.endDate,
    currentLocale,
  )

  const startAndEndDate = startDate && endDate ? `${startDate}-${endDate}` : ''

  const drawerRef = useRef<any>(null)
  const classes = useStyles({elivated})

  const SelectedStep =
    currentStep && (componentMapping[currentStep] as React.ElementType)

  const seletedStepDetails = currentStep && StepDetails[currentStep]
  const isSmallerSize = useSmallerSize()

  const stepProps = useMemo<Partial<BaseStepProps>>(() => {
    if (currentStep === 'date') {
      return {
        scrollToTop: () => {
          if (drawerRef.current) {
            drawerRef.current.scrollToTop()
          }
        },
      }
    }
    return {}
  }, [currentStep])

  const getCustomHeight = () => {
    if (currentStep === 'date' && !isSmallerSize) {
      return '50%'
    }

    return seletedStepDetails?.customHeight
  }

  return (
    <div className={classes.container}>
      <div className="grid grid-cols-1 gap-3">
        <div>
          <Field
            id="places_selection"
            label={t('location')}
            placeholder={t('location_description')}
            value={selectedLocation.address}
            onClick={() => setCurrentStep('location')}
          />
        </div>
        <div>
          <Field
            label={t('dates')}
            placeholder={t('add_dates')}
            value={startAndEndDate || ''}
            onClick={() => setCurrentStep('date')}
          />
        </div>
        <div>
          <Field
            label={t('guests')}
            placeholder={t('guests_description')}
            value={showGuests && <GuestField guests={guests} />}
            showIcon
            onClick={() => setCurrentStep('guests')}
          />
        </div>
        <div>
          <AppButton
            variant="contained"
            className={classes.handleSearchMobile}
            color="secondary"
            onClick={handleSearch}
          >
            {t('search')}
          </AppButton>
        </div>
      </div>
      <SearchDrawer
        ref={(ref) => (drawerRef.current = ref)}
        isOpen={!!currentStep}
        onClose={handleDrawerClose}
        fullHeight={seletedStepDetails?.fullHeight}
        title={seletedStepDetails?.label ? t(seletedStepDetails.label) : ''}
        customHeight={getCustomHeight()}
      >
        <Container>
          <>
            {SelectedStep && (
              <SelectedStep {...stepProps} changeStep={changeStep} />
            )}
          </>
        </Container>
      </SearchDrawer>
    </div>
  )
}
